import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, IPhone, img } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="text-xl leading-9">
  At Trailguide, we are committed to providing you with the best trail biking
  experience possible. Our app is continuously evolving, with new features and
  possibilities being added constantly.
    </p>
    <Image src="/news/tg_2023_spring_edition.jpg" className="mx-auto mt-12 mb-4" mdxType="Image" />
    <p className="text-lg leading-7">
  <br />
  <br />
  However, we must admit that we haven't been the best at keeping you informed
  about all the exciting updates. That's why we're here to share some of the
  remarkable features available in the app, empowering you to discover
  exceptional trails and navigate them effortlessly.
  <br />
  <br />
  Explore our vast collection of meticulously curated trails, spanning a wide range
  of difficulty levels and terrains. Whether you're a beginner seeking a leisurely
  ride or an experienced thrill-seeker craving an adrenaline rush, Trailguide has
  the perfect trail waiting for you.
  <br />
  <br />
  Dive into comprehensive trail descriptions, including detailed maps, images,
  videos, distance information, height profiles, reviews and condition reports.
  <br />
  <br />
  Say goodbye to getting lost. Trailguide provides great tools for better navigation,
  keeping you on track and ensuring a seamless journey. No more second-guessing or
  wasting time on unnecessary detours.
  <br />
  <br />
  If you are not a user yet, join the Trailguide community today and unlock a
  world of trail biking possibilities. With our continuously expanding features
  and user-friendly design, your biking adventures will reach new heights.
  <br />
  <br />
  Note: Remember to enable location services to enjoy all the features of the app.
  <br />
  Happy Trails,
  <br />
  The Trailguide Team
    </p>
    <br />
    <p><a parentName="p" {...{
        "href": "/howto/install/"
      }}>{`Install Trailguide`}</a>{` |
`}<a parentName="p" {...{
        "href": "/howto/gps-on-iphone/"
      }}>{`Make GPS work on iPhone`}</a>{` |
`}<a parentName="p" {...{
        "href": "/howto/navigation/"
      }}>{`Navigation on iPhone`}</a></p>
    <div className="flex flex-wrap justify-around">
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Trailguide map</h3>
    <IPhone alt="Trailguide 2023 Spring Edition" src={img("/website/trailguide-iphone-world-4.jpg")} text="The main screen of Trailguide with the map filled with trails." mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">List of trails</h3>
    <IPhone src={img("/website/trailguide-iphone-list-trails.jpg")} alt="Browse trails, reviews, and condition reports within the current map section" text="Browse trails, reviews, and condition reports within the current map section" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Trail details</h3>
    <IPhone src={img("/website/trailguide-iphone-page-activity.jpg")} alt="Lots of details are available for trails, including images and videos" text="Lots of details are available for trails, including images and videos" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Mark trail</h3>
    <IPhone src={img("/website/trailguide-iphone-page-show.jpg")} alt="Clicking 'Show on map' in the trail description will mark it yellow on the map" text="Clicking 'Show on map' in the trail description will mark it yellow on the map" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Height profile</h3>
    <IPhone src={img("/website/trailguide-iphone-map-track.jpg")} alt="Drag your finger across the height profile to see where on the track the ups and downs are" text="Drag your finger across the height profile to see where on the track the ups and downs are" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Hide categories</h3>
    <IPhone src={img("/website/interface/iphone_hide_categories.jpeg")} alt="Hide the category selector out of the way if you want more map space" text="Hide the category selector out of the way if you want more map space" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Transparency</h3>
    <IPhone src={img("/website/trailguide-iphone-transparent.jpg")} alt="Using the transparency adjuster you can check what is underneath the trail marking - is it a trail or a gravel road?" text="Using the transparency adjuster you can check what is underneath the trail marking - is it a trail or a gravel road?" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Navigation mode</h3>
    <IPhone src={img("/howto/trailguide-iphone-navigation-2.jpg")} alt="Turn on navigation mode for the location indicator to follow you and show your current direction" text="Turn on navigation mode for the location indicator to follow you and show your current direction" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Breadcrumbs</h3>
    <IPhone src={img("/website/trailguide-iphone-breadcrumbs.png")} alt="In navigation mode breadcrumbs will indicate where you have been to help you find where to go" text="In navigation mode breadcrumbs will indicate where you have been to help you find where to go" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Heading up</h3>
    <IPhone src={img("/website/trailguide-iphone-heading-up.jpg")} alt="Click the compass needle to toggle between north-up or heading-up" text="Click the compass needle to toggle between north-up or heading-up" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Toggle mode</h3>
    <IPhone src={img("/website/trailguide-iphone-navigation-3.jpg")} alt="Click for more than 1 second on the location icon in the nav bar to toggle between navigation mode and normal mode" text="Click for more than 1 second on the location icon in the nav bar to toggle between navigation mode and normal mode" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Map selector</h3>
    <IPhone src={img("/howto/mtbmap-2.jpg")} alt="Select between different base maps and map details such as strava heatmaps, MTB tracks and cycle routes" text="Select between different base maps and map details such as strava heatmaps, MTB tracks and cycle routes" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">MTBmap.no</h3>
    <IPhone src={img("/howto/mtbmap-3.jpg")} alt="Use MTBmap as the base map (only works in Norway and for limited zoom levels)" text="Use MTBmap as the base map (only works in Norway and ifor limited zoom levels))" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">OSM MTB tracks</h3>
    <IPhone src={img("/website/trailguide-iphone-mtbtracks.jpg")} alt="Same dataset as the mtbmap base layer, but it works all over the world and for any zoom level" text="Same dataset as the mtbmap base layer, but it works all over the world and for any zoom level" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Strava heatmap</h3>
    <IPhone src={img("/website/trailguide-iphone-heatmap.jpg")} alt="Use the Strava heatmap to help you find popular or hidden trails. Unfortunately, the heatmap is limited to zoom level 11 and below." text="Use the Strava heatmap to help you find popular or hidden trails. Unfortunately, the heatmap is limited to zoom level 11 and below." mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Cycle routes</h3>
    <IPhone src={img("/website/trailguide-iphone-cycleroutes.jpg")} alt="Show cycle routes from OpenStreetMap to help you find your way on a road bike" text="Show cycle routes from OpenStreetMap to help you find your way on a road bike" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Grayscale map</h3>
    <IPhone src={img("/website/trailguide-iphone-grayscale.jpg")} alt="Set the map to grayscale to better see the available trails" text="Set the map to grayscale to better see the available trails" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Satellite map</h3>
    <IPhone src={img("/website/trailguide-iphone-satellite.jpg")} alt="Use the satellite base map to see what is really on the ground" text="Use the satellite base map to see what is really on the ground" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Trail reviews</h3>
    <IPhone src={img("/website/trailguide-iphone-page-reviews.jpg")} alt="See what others have said about the trail" text="See what others have said about the trail" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Condition reports</h3>
    <IPhone src={img("/website/trailguide-iphone-list-conditions.jpeg")} alt="Check recent condition reports within the current map section" text="Check recent condition reports within the current map section" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Leave a review</h3>
    <IPhone src={img("/website/trailguide-iphone-page-writereview.jpg")} alt="Leave a review and let others know what you think about a trail" text="Leave a review and let others know what you think about a trail" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Leave a report</h3>
    <IPhone src={img("/website/trailguide-iphone-page-writereport.jpeg")} alt="Leave a condition report and let others know if it is muddy or dry" text="Leave a condition report and let others know if it is muddy or dry" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Search</h3>
    <IPhone src={img("/website/trailguide-iphone-search.jpeg")} alt="Search for places, trails, users and destinations" text="Search for places, trails, users and destinations" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Filter</h3>
    <IPhone src={img("/website/trailguide-iphone-filter.jpeg")} alt="Filter content by difficulty and good reviews and condition reports" text="Filter content by difficulty and good reviews and condition reports" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Split map</h3>
    <IPhone src={img("/website/trailguide-iphone-splitmap.jpg")} alt="You can split the map in two halves if you want to investigate if a marked trail on the map really is worth exploring" text="You can split the map in two halves if you want to investigate if a marked trail on the map really is worth exploring" mdxType="IPhone" />
  </div>
  <div className="w-1/3 m-4 my-12">
    <h3 className="text-center mb-4">Local services</h3>
    <IPhone src={img("/website/trailguide-iphone-presence2.jpg")} alt="Find information and details about businesses in the area" text="Find information and details about businesses in the area" mdxType="IPhone" />
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      